<template>
  <transition
    mode="out-in"
    @before-enter="handleBeforeEnter"
    @enter="handleEnter"
    @leave="handleLeave"
  >
    <div v-if="project" :key="projectSlug" class="project-page">
      <Slider 
        v-if="hasImages" 
        ref="slider" 
        :images="project.projectimages" 
        @ready="handleSliderReady"
      />

      <div class="project-name">
        <h3 @click="navigateToPreviousProject" class="Previous">PREV</h3>
        <h3 @click.stop="toggleDescription" class="this-project">
          ABOUT {{ project.projectName }}
        </h3>
        <h3 @click="navigateToNextProject" class="Next">NEXT</h3>
      </div>

      <div v-if="showDescription" ref="description" class="project-description">
        <p>{{ project.projectDescription }}</p>
      </div>
    </div>
    <div v-else class="loading">Loading...</div>
  </transition>
</template>

<script>
import { defineComponent, ref, onBeforeUnmount, watch, nextTick } from 'vue';
import useProjectData from "@/mixins/useProjectData";
import Slider from "@/components/Slider.vue";

export default defineComponent({
  name: 'ProjectDetail',
  components: { Slider },
  mixins: [useProjectData],
  props: {
    projectSlug: {
      type: String,
      required: true
    }
  },
  setup() {
    const showDescription = ref(false);
    const sliderReady = ref(false);
    const sliderRef = ref(null);

    return {
      showDescription,
      sliderReady,
      sliderRef
    };
  },
  computed: {
    project() {
      return this.getProjectBySlug(this.projectSlug);
    },
    hasImages() {
      return this.project?.projectimages?.length > 0;
    }
  },
  watch: {
    async projectSlug() {
      await this.resetSlider();
    }
  },
  mounted() {
    document.addEventListener("click", this.closeDescriptionOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeDescriptionOutside);
    this.cleanupSlider();
  },
  methods: {
    handleSliderReady() {
      this.sliderReady = true;
    },
    async resetSlider() {
      await nextTick();
      if (this.$refs.slider && typeof this.$refs.slider.resetSwiper === 'function') {
        await this.$refs.slider.resetSwiper();
      }
    },
    cleanupSlider() {
      if (this.$refs.slider && typeof this.$refs.slider.cleanup === 'function') {
        this.$refs.slider.cleanup();
      }
    },
    handleBeforeEnter(el) {
      el.style.opacity = '0';
    },
    handleEnter(el, done) {
      setTimeout(() => {
        el.style.transition = 'opacity 0.5s';
        el.style.opacity = '1';
        done();
      }, 0);
    },
    handleLeave(el, done) {
      el.style.transition = 'opacity 0.5s';
      el.style.opacity = '0';
      setTimeout(() => {
        done();
      }, 500);
    },
    async navigateToPreviousProject() {
      const currentIndex = this.projects.findIndex(p => p.id === this.project?.id);
      if (currentIndex === -1) return;

      const prevSlug = currentIndex > 0 
        ? this.projects[currentIndex - 1].projectSlug 
        : this.projects[this.projects.length - 1].projectSlug;

      await this.cleanupSlider();
      await this.$router.push({
        name: "ProjectDetail",
        params: { projectSlug: prevSlug },
      });
    },
    async navigateToNextProject() {
      const currentIndex = this.projects.findIndex(p => p.id === this.project?.id);
      if (currentIndex === -1) return;

      const nextSlug = currentIndex < this.projects.length - 1 
        ? this.projects[currentIndex + 1].projectSlug 
        : this.projects[0].projectSlug;

      await this.cleanupSlider();
      await this.$router.push({
        name: "ProjectDetail",
        params: { projectSlug: nextSlug },
      });
    },
    toggleDescription() {
      this.showDescription = !this.showDescription;
    },
    closeDescriptionOutside(event) {
      if (
        this.showDescription &&
        this.$refs.description &&
        !this.$refs.description.contains(event.target)
      ) {
        this.showDescription = false;
      }
    }
  }
});
</script>


<style scoped>
.project-page {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.loading-state,
.error-state {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  gap: 1rem;
}

.loading-spinner {
  width: 100px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.project-name {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  z-index: 500;
}

.project-description {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: clamp(24px, 3vw, 30px);
  color: black;
  max-width: 800px;
  z-index: 100;
  border-radius: 10px;
  background-color: rgba(137, 137, 137, 0.37);
  backdrop-filter: blur(10px);
  padding: 24px;
  font-family: 'Kommuna', sans-serif;
  line-height: 1.1;
  margin: 0;
  width: 90%;
  box-sizing: border-box;
}

.project-description p {
  margin: 0;
  font-weight: normal;
}

.project-description-content {
  width: 100%;
  font-family: 'Kommuna', sans-serif;
  font-size: clamp(1rem, 2.5vw, 1.875rem);
  line-height: 1.1;
}

.this-project,
.Previous,
.Next {
  background-color: rgba(170, 171, 204, 0.37);
  border-radius: 8px;
  padding: 8px 16px;
  backdrop-filter: blur(10px);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.this-project {
  flex: 1;
  text-align: center;
  margin: 0 5px;
}

.this-project:hover,
.Previous:hover,
.Next:hover {
  background-color: rgba(170, 171, 204, 0.5);
}

/* Transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Error state styles */
.error-state button {
  padding: 0.5rem 1rem;
  background: rgba(170, 171, 204, 0.37);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-family: inherit;
  transition: background-color 0.3s ease;
}

.error-state button:hover {
  background: rgba(170, 171, 204, 0.5);
}

/* Mobile styles */
@media (max-width: 768px) {
  .project-description {
    font-size: 18px;
    padding: 20px;
    width: 85%;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .project-description-content {
    font-size: clamp(0.875rem, 2vw, 1.125rem);
  }
  
  .project-name {
    padding: 0 10px;
  }
  
  .this-project,
  .Previous,
  .Next {
    padding: 6px 12px;
    font-size: 0.9rem;
  }
}
</style>