```vue
<template>
  <div class="canvas-container">
    <canvas v-bind:id="canvasId" tabIndex="1"></canvas>
  </div>
</template>

<script>
import logger from '@/utils/logger';

export default {
  props: {
    patchDir: String,
    canvasId: {
      type: String,
      default: 'glcanvas'
    },
    patchOptions: Object,
    projectsData: Object
  },
  
  mounted() {
  this.initializePatch();
  window.addEventListener('beforeunload', this.cleanup);
},

beforeDestroy() {
  window.removeEventListener('beforeunload', this.cleanup);
},
  
  methods: {
    async initializePatch() {
      try {
        // Load MediaPipe Face Mesh first
        await new Promise((resolve, reject) => {
          const mediaPipeScript = document.createElement('script');
          mediaPipeScript.src = 'https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh@0.4.1633559619/face_mesh.js';
          mediaPipeScript.async = true;
          mediaPipeScript.onload = resolve;
          mediaPipeScript.onerror = reject;
          document.body.appendChild(mediaPipeScript);
        });

        // Then load the patch script
        await new Promise((resolve, reject) => {
          const patchScript = document.createElement('script');
          patchScript.src = `${this.patchDir}/js/patch.js`;
          patchScript.async = true;
          patchScript.onload = () => {
            try {
              const canvas = document.getElementById(this.canvasId);
              if (!canvas) {
                throw new Error('Canvas element not found');
              }

              const gl = canvas.getContext('webgl2', {
                alpha: true,
                antialias: true,
                depth: true,
                failIfMajorPerformanceCaveat: false,
                powerPreference: 'high-performance',
                premultipliedAlpha: false,
                preserveDrawingBuffer: true,
                stencil: true
              });

              if (!gl) {
                throw new Error('WebGL2 not available');
              }

              const options = {
                patch: window.CABLES.exportedPatch,
                prefixAssetPath: this.patchDir + '/',
                jsPath: this.patchDir + '/js/',
                glCanvasId: this.canvasId,
                glCanvasResizeToWindow: false,
                canvas: {
                  gl: gl,
                  alpha: true,
                  premultipliedAlpha: false,
                  preserveDrawingBuffer: true,
                  antialias: true
                },
                onFinishedLoading: () => {
                  logger.log(this.patchDir + ' finished loading');
                  this.$emit('patch-loaded');
                },
                onError: (err) => {
                  console.error('Patch error:', err);
                },
                variables: {
                  inputJson: this.projectsData,
                  HiresDisplay: window.devicePixelRatio || 1,
                  showUI: 0,
                  enableWebcam: 1,
                  showWebcam: 1,
                  displayEyes: 1
                },
                ...this.patchOptions
              };

              window.CABLES.patch = new window.CABLES.Patch(options);
              resolve();
            } catch (err) {
              reject(err);
            }
          };
          patchScript.onerror = reject;
          document.body.appendChild(patchScript);
        });
      } catch (err) {
        console.error('Failed to initialize patch:', err);
        throw err;
      }
    },

    async cleanup() {
  try {
    // Pause animations first
    if (window.CABLES?.patch) {
      window.CABLES.patch.pause();
      window.cancelAnimationFrame(window.CABLES.patch._animFrameHandle);
      
      // Wait for frame to complete
      await new Promise(resolve => setTimeout(resolve, 50));
      
      // Reset context state
      const canvas = document.getElementById(this.canvasId);
      if (canvas) {
        const gl = canvas.getContext('webgl2');
        if (gl) {
          try {
            gl.flush();
            gl.finish();
            gl.bindFramebuffer(gl.FRAMEBUFFER, null);
            gl.bindRenderbuffer(gl.RENDERBUFFER, null);
            gl.bindBuffer(gl.ARRAY_BUFFER, null);
            gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
            gl.bindTexture(gl.TEXTURE_2D, null);
            gl.useProgram(null);
            gl.disable(gl.DEPTH_TEST);
            gl.disable(gl.BLEND);
            gl.disable(gl.CULL_FACE);
            gl.disable(gl.STENCIL_TEST);
            gl.getExtension('WEBGL_lose_context')?.loseContext();
          } catch (e) {
            // Ignore WebGL errors during cleanup
          }
        }
      }
      
      window.CABLES.patch.dispose();
      window.CABLES.patch = null;
    }

    await this.$nextTick();
  } catch (e) {
    console.warn('Cleanup error:', e);
  }
}
  },
  
  beforeUnmount() {
    this.cleanup();
  }
};
</script>

<style>
.canvas-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
}

canvas {
  width: 100% !important;
  height: 100% !important;
  background: transparent !important;
}

@media (max-width: 768px) {
  .canvas-container {
    height: calc(100vh - env(safe-area-inset-bottom, 0px));
  }
  
  canvas {
    height: calc(100vh - env(safe-area-inset-bottom, 0px)) !important;
  }
}
</style>
```