<template>
  <div class="main">
    <div class="content">
      <img class="video" src="/D-SF.gif" alt="Decorative animation">
      
      <div v-if="!permissionGiven" class="proceed">
        <h3>{{ decision1 }}</h3>
        <div class="btn">
          <button @click="handleMe">Pass</button>
          <button @click="handleProceed">Proceed</button>
        </div>
      </div>

      <div v-if="permissionGiven" class="enter">
        <h3 v-html="decision2"></h3>
        <div class="btn">
          <button @click="handleEnter">Enter</button>
        </div>
      </div>

      <div v-if="error" class="error-message">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { fetchAbout } from "@/api/api";

export default {
  data() {
    return {
      permissionGiven: false,
      decision1: '',
      decision2: '',
      currentStream: null,
      error: null
    };
  },
  async created() {
    try {
      const aboutInfo = await fetchAbout();
      if (!aboutInfo) {
        throw new Error('No about data received');
      }
      
      this.decision1 = aboutInfo.decision1 || '';
      this.decision2 = aboutInfo.decision2?.html || '';
      
    } catch (error) {
      console.error("Error fetching data:", error);
      this.error = "Could not load content. Please try again.";
    }
  },
  beforeUnmount() {
    this.stopMediaStream();
  },
  methods: {
    handleMe() {
      this.$router.push("/projects");
    },
    async handleProceed() {
      try {
        // Check if camera is available
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasCamera = devices.some(device => device.kind === 'videoinput');
        
        if (!hasCamera) {
          throw new Error('No camera found on this device');
        }

        const stream = await navigator.mediaDevices.getUserMedia({
          video: { 
            facingMode: 'user',
            width: { ideal: 1280 },
            height: { ideal: 720 }
          }
        });
        
        this.currentStream = stream;
        this.permissionGiven = true;
        this.error = null;
        
      } catch (error) {
        console.error("Camera error:", error);
        
        // Handle specific error cases
        if (error.name === 'NotFoundError') {
          this.error = "No camera found on this device. Redirecting to alternative experience...";
        } else if (error.name === 'NotAllowedError') {
          this.error = "Camera access denied. Please enable camera access to continue.";
        } else {
          this.error = "Could not access camera. Redirecting to alternative experience...";
        }
        
        // Allow error message to show before redirect
        setTimeout(() => {
          this.$router.push("/blinking");
        }, 2000);
      }
    },
    handleEnter() {
      this.stopMediaStream();
      this.$emit("proceed");
    },
    stopMediaStream() {
      if (this.currentStream) {
        this.currentStream.getTracks().forEach(track => {
          try {
            track.stop();
          } catch (e) {
            console.error('Error stopping track:', e);
          }
        });
        this.currentStream = null;
      }
    }
  }
};
</script>

<style scoped>
.main {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(231, 235, 238, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 18px;
  padding: 20px;
  margin: 10%;
  gap: 20px;
  max-width: 500px;
}

.video {
  width: 100%;
  max-width: 200px;
}

.error-message {
  color: #721c24;
  background-color: rgba(248, 215, 218, 0.8);
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
  font-size: 14px;
}

h3 {
  margin: 0.5em 0;
  font-size: clamp(18px, 3vw, 24px);
  line-height: 1.4;
}

.btn {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.btn button {
  padding: 10px 30px;
  border: none;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.3);
  transition: all 0.3s ease;
  font-family: inherit;
  font-size: clamp(16px, 2vw, 20px);
  cursor: pointer;
  color: inherit;
}

.btn button:hover {
  background-color: #fff;
  transform: translateY(-1px);
}

.btn button:active {
  transform: translateY(1px);
}

@media (max-width: 768px) {
  .content {
    width: 90%;
    margin: 5%;
    padding: 15px;
  }

  .video {
    max-width: 150px;
  }
}
</style>