<script setup>
import { ref, onMounted } from 'vue';
import Decision from "@/components/Decision.vue";

// Manage the visibility state
const isVisible = ref(false);

onMounted(() => {
  setTimeout(() => {
    isVisible.value = true;
  }, 2000); // 2 seconds delay
});
</script>

<template>
  <div class="decision-container">
    <transition name="fade">
      <div v-if="isVisible">
        <Decision @proceed="handleProceed" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  methods: {
    handleProceed() {
      this.$router.push("/blinking");
    },
  },

  computed: {
    readyToRender() {
      return this.projects.length > 0 && this.cameraReady;
    },
  },
  mounted() {
    // Disable the logo when the component mounts
    const logo = document.querySelector('.logo');
    if (logo) {
      logo.style.display = 'none'; // Hide the logo
    }
  },
  beforeUnmount() {
    // Reset the logo visibility when the component is about to be destroyed
    const logo = document.querySelector('.logo');
    if (logo) {
      logo.style.display = ''; // Remove the inline style to reset its visibility
    }
  },
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}
.fade-leave-from, .fade-leave-to {
  opacity: 0;
}

/* Add these new styles */
.decision-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Prevents scrolling */
}

/* Add these button styles */
button {
  padding: 10px 30px;
  border: none;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.3);
  transition: all 0.3s ease;
  font-family: inherit;
  font-size: clamp(16px, 2vw, 20px);
  cursor: pointer;
}

button:hover {
  background-color: #fff;
  transform: translateY(-1px);
}

button:active {
  transform: translateY(1px);
}
</style>
