<template>
  <main>
    <div v-if="loading" class="loading-screen fade-in-out">
      <p>LOADING<br>YOUR<br>SELECTION</p>
    </div>
    <div v-else-if="error" class="error-screen">
      <p>Something went wrong. Please try again.</p>
      <button @click="retryLoading">Retry</button>
    </div>
    <Slider
      v-else-if="selectedProjectsImages.length"
      ref="slider"
      :images="selectedProjectsImages"
      @toggle-description="toggleDescription"
      @image-loaded="onImageLoad"
    />
    <div
      v-if="showDescription"
      @click="closeDescription"
      ref="description"
      class="project-description"
    >
      <p>{{ selectedProjectDescription }}</p>
    </div>
  </main>
</template>

<script>
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import Slider from "@/components/Slider.vue";
import useProjectData from "@/mixins/useProjectData";

export default defineComponent({
  name: 'SelectedProjects',
  components: {
    Slider
  },
  mixins: [useProjectData],
  setup() {
    const loading = ref(true);
    const error = ref(false);
    const showDescription = ref(false);
    const selectedProjectDescription = ref('');
    const sliderRef = ref(null);

    return {
      loading,
      error,
      showDescription,
      selectedProjectDescription,
      sliderRef
    };
  },
  computed: {
    selectedProjects() {
      // Add error handling for empty state
      try {
        if (this.$store.state.selectedProjects.length === 0) {
          const random = [...this.$store.state.loadedProjects]
            .sort(() => 0.5 - Math.random())
            .slice(0, 3);
          return random;
        }
        return this.$store.state.selectedProjects;
      } catch (err) {
        console.error('Error getting selected projects:', err);
        return [];
      }
    },
    selectedProjectsImages() {
      return this.selectedProjects.reduce((acc, item) => {
        if (item) {
          acc.push({
            type: "link",
            name: item.projectName,
            slug: item.projectSlug,
            description: item.projectDescription,
          });

          if (Array.isArray(item.projectimages)) {
            acc.push(...item.projectimages);
          }
        }
        return acc;
      }, []);
    }
  },
  methods: {
    async retryLoading() {
      this.error = false;
      this.loading = true;
      await this.loadInitialData();
    },
    async loadInitialData() {
      try {
        await this.$store.dispatch('loadProjects');
        
        // Add a small delay to ensure store is updated
        await new Promise(resolve => setTimeout(resolve, 500));
        
        if (!this.selectedProjectsImages.length) {
          throw new Error('No projects loaded');
        }
        
        this.loading = false;
      } catch (err) {
        console.error('Error in loadInitialData:', err);
        this.error = true;
        this.loading = false;
      }
    },
    toggleDescription(description) {
      if (description) {
        this.selectedProjectDescription = description;
        this.showDescription = !this.showDescription;
      }
    },
    closeDescription() {
      this.showDescription = false;
    },
    onImageLoad() {
      // Handle image load event if needed
    },
    cleanup() {
      if (this.$refs.slider && typeof this.$refs.slider.cleanup === 'function') {
        this.$refs.slider.cleanup();
      }
    }
  },
  async mounted() {
    await this.loadInitialData();
  },
  beforeUnmount() {
    this.cleanup();
  }
});
</script>

<style scoped>
.selected-projects {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.content-wrapper {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

/* Loading Screen */
.loading-screen {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  z-index: 1000;
  text-align: center;
  font-size: clamp(24px, 5vw, 50px);
}



.project-description {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: clamp(24px, 3vw, 30px);
  color: black;
  max-width: 800px;
  z-index: 100;
  border-radius: 10px;
  background-color: rgba(137, 137, 137, 0.37);
  backdrop-filter: blur(10px);
  padding: 24px;
  font-family: 'Kommuna', sans-serif;
  line-height: 1.1;
  margin: 0;
  width: 90%;
  box-sizing: border-box;
}

.project-description p {
  margin: 0;
  font-weight: normal;
}

.project-description-content {
  width: 100%;
  font-family: 'Kommuna', sans-serif;
  font-size: clamp(1rem, 2.5vw, 1.875rem);
  line-height: 1.1;
}

/* Error State */
.error-state {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  text-align: center;
}

.error-state button {
  padding: 0.75rem 1.5rem;
  background: rgba(0, 0, 0, 0.05);
  border: none;
  border-radius: 0.5rem;
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.error-state button:hover {
  background: rgba(0, 0, 0, 0.1);
}

/* Animations */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-in-out {
  animation: fadeInOut 2.5s ease-in-out forwards;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .project-description-content {
    padding: 24px;
    font-size: clamp(24px, 5vw, 30px);
    line-height: 1.1;
  }

  /* Ensure about cards are centered on mobile */
  :deep(.about-card) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 2rem 1rem;
  }

  :deep(.arrow-icon) {
    transform: rotate(90deg);
    margin-top: 1rem;
  }
}

/* Scrollbar Styling */
.project-description-content::-webkit-scrollbar {
  width: 8px;
}

.project-description-content::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.project-description-content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
</style>